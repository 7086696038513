<script setup>
import { useI18n } from "vue-i18n";
import { useElements } from "./composables/elements";

const { errors, renderElement, storeElement, updateElement } = useElements();

const props = defineProps({
  elements: { type: String, required: true },
});

const { t } = useI18n(); // call `useI18n`, and spread `t` from  `useI18n` returning
</script>

<template>
  <div class="container-fluid vh-100 p-0 app-view">
    <div
      class="element-block"
      v-for="element in props.elements"
      :key="element.id"
    >
      <component
        :is="renderElement(element.type, 'template')"
        :pageId="element.page_id"
        :data="element ? element : {}"
      />
    </div>
  </div>
</template>

<style>
.app-view {
  max-width: 720px !important;
}
</style>