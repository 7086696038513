import { createApp } from 'vue'
import { vue3Debounce } from 'vue-debounce'
import { createI18n } from "vue-i18n/dist/vue-i18n.esm-bundler.js";
import { messages } from './i18n/messages'
import store from './store'
import { useHttp } from './composables/http'
import { createRouter, createWebHistory } from 'vue-router';

import "bootstrap"
import "./assets/scss/_website.scss"
import "./assets/scss/palettes/_palettes.scss"

import PageApp from './PageApp.vue'

// Add i18n
const i18n = createI18n({
    legacy: false,
    locale: import.meta.env.APP_LOCALE ? import.meta.env.APP_LOCALE : window.navigator.language,
    fallbackLocale: import.meta.env.FALLBACK_LOCALE ? import.meta.env.FALLBACK_LOCALE : 'en',
    messages,
})

// get data from data of the element to mount
const app = createApp(PageApp, { elements: pageElements });

const $http = useHttp();
app.config.globalProperties.$axios = $http
store.$axios = $http

const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes: [],
});

app.directive('debounce', vue3Debounce({ lock: true }));
app.use(i18n).use(store).use(router);
app.mount('#pageApp')
